import {DirectionalLight, ShadowGenerator} from "babylonjs";
import {Vector3} from "babylonjs";

export function create(Viewer) {

    let Scene = Viewer.SceneManager.Scene;

    let light = new DirectionalLight("dir01", new Vector3(0, -0.5, -1.0), Scene);
        light.position = new Vector3(0, 5, 5);

    setTimeout(()=> {
        let shadowGenerator = new ShadowGenerator(4096, light);

        for (let mesh of Viewer.SceneManager.scene.meshes) {
            shadowGenerator.addShadowCaster(mesh);
        }
    },1000);



    return light;

}