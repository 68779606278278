import {ActionManager as B_ActionManager} from "babylonjs";
import {ExecuteCodeAction} from "babylonjs";

import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

/**
 * https://doc.babylonjs.com/divingDeeper/events/actions
 */
export class ActionManager extends BaseManager implements IManager {

    InputMap = {};

    async init() {
        let Scene = this.viewer.SceneManager.scene;

        // Keyboard events
        let inputMap = this.InputMap;
        Scene.actionManager = new B_ActionManager(Scene);
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnKeyDownTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
        }));
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnKeyUpTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
        }));

        // Mouse events

        // left click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnLeftPickTrigger, function (evt) {
            console.debug(evt);
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
        }));

        // right click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnRightPickTrigger, function (evt) {
            console.debug(evt);
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
        }));

        // center click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnCenterPickTrigger, function (evt) {
            console.debug(evt);
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
        }));

    }
}