import {GenKey} from "./Generator";

export interface IBaseClass {};

export class BaseClass {

    uniqueId:string;

    constructor() {

        this.uniqueId = GenKey();


        if (typeof window.LVPX !== 'undefined') {
            if (typeof window.LVPX.Cache === 'undefined') {
                window.LVPX.Cache = {
                    "Instances": {}
                }
            }
            window.LVPX.Cache.Instances[this.uniqueId] = this;
        }

    }


}