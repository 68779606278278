import {
    Color3,
    Color4,
    MeshBuilder,
    PointerEventTypes,
    StandardMaterial,
    VideoTexture,
    TransformNode,
    PointerDragBehavior
} from "babylonjs";
import {Vector3} from "babylonjs";
import {Mesh} from "babylonjs";

export function create(viewer) {

    //https://playground.babylonjs.com/#ZMCFYA#2

    let scene = viewer.SceneManager.scene;

    let rootNode = new TransformNode("rootNode");


    var ANote0 = MeshBuilder.CreateBox("ANote0", {width: 7.646700*3, height: 5.726200*3, depth: 0.100000 }, scene);
    ANote0.position = new Vector3(0, 13, -20);

    ANote0.parent = rootNode;





    var mat = new StandardMaterial("ANote0Mat",scene);
    //mat.diffuseColor = new Color4(0, 0, 0, 1);
    ANote0.material = mat;
    var planeOpts = {
        height: 5.4762*3,
        width: 7.3967*3,
        sideOrientation: Mesh.BACKSIDE
    };
    var ANote0Video = MeshBuilder.CreatePlane("plane", planeOpts, scene);
    var vidPos = (new Vector3(0,0, 0.01)).addInPlace(ANote0.position);
    ANote0Video.position = vidPos;

    var ANote0VideoMat = new StandardMaterial("m", scene);
    var ANote0VideoVidTex = new VideoTexture("vidtex","res/textures/babylonjs.mp4", scene);
    ANote0VideoMat.diffuseTexture = ANote0VideoVidTex;
    ANote0VideoMat.roughness = 1;
    ANote0VideoMat.emissiveColor = Color3.White();
    ANote0Video.material = ANote0VideoMat;

    let fadedOut = true;
    //let element = ANote0;
    let elements:Array<any> = [
        ANote0,
        ANote0Video
    ];

    ANote0Video.parent = rootNode;


    var pointerDragBehavior = new PointerDragBehavior({});
    pointerDragBehavior.useObjectOrientationForDragging = false;
    rootNode.addBehavior(pointerDragBehavior);



    scene.registerBeforeRender(function() {
        for (let element of elements) {
            if (element.visibility < 1.1 && !fadedOut){
                if (element.visibility < 0.0){
                    fadedOut = true;
                }

                element.visibility -= 0.001;
            } else {
                if (element.visibility > 1){
                    fadedOut = false;
                }
                element.visibility += 0.001;
            }
        }
    });


    scene.onPointerObservable.add(function(evt){
        if(evt.pickInfo.pickedMesh === ANote0Video){
            //console.log("picked");
            if(ANote0VideoVidTex.video.paused)
                ANote0VideoVidTex.video.play();
            else
                ANote0VideoVidTex.video.pause();
            console.log(ANote0VideoVidTex.video.paused?"paused":"playing");
        }
    }, PointerEventTypes.POINTERPICK);

}