import {MeshBuilder, PhysicsImpostor} from "babylonjs";
import {StandardMaterial} from "babylonjs";
import {Texture} from "babylonjs";
import {Color3} from "babylonjs";


export function create(Viewer) {

    let Scene = Viewer.SceneManager.scene;

    /*
    let groundMaterial = new StandardMaterial("groundMaterial", Scene);
    let texture = new Texture("res/textures/wood.jpg", Scene);
        texture.uScale = 30;
        texture.vScale = 30;
        groundMaterial.diffuseTexture = texture
        groundMaterial.specularColor = new Color3(.1, .1, .1);
     */

    let groundMaterial = new StandardMaterial("groundMaterial", Scene);
    //let texture = new Texture("http://osd.lordfox.de:8081/mse/components/Viewer/res/textures/Lordfox_Logo_Wallpaper_1920x1080.png", Scene);
    //let texture = new Texture("res/textures/Lordfox_Logo_Wallpaper_1920x1080.png", Scene);
    //let texture = new Texture("https://app.lordfox.de/mse/cdn/img/lordfox-wallpapers/lvp-wallpaper-trans5.png", Scene);


    let texture = new Texture(new URL('lvp-wallpaper-trans5.png', import.meta.url).href, Scene) ;
        texture.uScale = 1;
        texture.vScale = 1;
        texture.wRotationCenter = 0;
        texture.wAng = Math.PI;
        groundMaterial.diffuseTexture = texture
        groundMaterial.specularColor = new Color3(.1, .1, .1);
        groundMaterial.alpha = 1.61;


    let ground = MeshBuilder.CreateGround("ground", {
            height: 512,
            width: 512,
            subdivisions: 16
        }, Scene);
        ground.material = groundMaterial;
        ground.checkCollisions = true;
        ground.receiveShadows = true;

        //ground.physicsImpostor = new PhysicsImpostor(ground,
        //PhysicsImpostor.BoxImpostor, { mass: 0 }, Scene);

    return ground;

}