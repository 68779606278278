import {IManager, IManagerConstructor} from './IManager';
import {Viewer} from "../Viewer";

import * as DefaultScene from '../Scenes/DefaultScene';
import * as DefaultCamera from '../Cameras/DefaultCamera';
import * as DefaultGround from '../Grounds/DefaultGround';

import * as DirectionalLight1 from '../Lights/DirectionalLight1';
import * as HemisphericLight1 from '../Lights/HemisphericLight1';

import * as Foxilia_Samba from '../Characters/Foxilia_Samba';
import * as VideoTexture from '../Meshes/VideoTexture';
import * as SkyboxTropical from '../Meshes/SkyboxTropical';
import * as SimpleBox from '../Meshes/SimpleBox';



export const BaseManager: IManagerConstructor = class BaseManager implements IManager {

    viewer:Viewer;

    elements:Object = {};

    //rootPath = "http://127.0.0.1:8081/mse/components/Viewer/src/";
    rootPath = "../";

    constructor(viewer:Viewer) {
        this.viewer = viewer;
    }

    async init() {}

    async importByName(name: string, type = 'Cameras') {
        let Factory;

        switch (type) {

            case 'Cameras':
                if (name === 'DefaultCamera') Factory = DefaultCamera;
                break;

            case 'Grounds':
                if (name === 'DefaultGround') Factory = DefaultGround;
                break;

            case 'Lights':
                if (name === 'DirectionalLight1') Factory = DirectionalLight1;
                if (name === 'HemisphericLight1') Factory = HemisphericLight1;
                break;

            case 'Characters':
                //if (name === 'Foxilia') Factory = await import('../Characters/Foxilia');
                if (name === 'Foxilia_Samba') Factory = Foxilia_Samba;
              //  if (name === 'Foxilia_Test') Factory = await import('../Characters/Foxilia_Test');
              //  if (name === 'Miguel') Factory = await import('../Characters/Miguel');
             //   if (name === 'NPC') Factory = await import('../Characters/NPC');
                break;

            case 'Meshes':
                if (name === 'SimpleBox') Factory = SimpleBox;
            //    if (name === 'SkyboxCity') Factory = await import('../Meshes/SkyboxCity');
            //    if (name === 'SkyboxClouds') Factory = await import('../Meshes/SkyboxClouds');
            //    if (name === 'SkyboxSpace') Factory = await import('../Meshes/SkyboxSpace');
                if (name === 'SkyboxTropical') Factory = SkyboxTropical;
              //  if (name === 'SmartTV') Factory = await import('../Meshes/SmartTV');
                if (name === 'VideoTexture') Factory = VideoTexture;

                break;

            case 'Others':
              //  if (name === 'Youtube') Factory = await import('../Others/Youtube');
                break;

            case 'Scenes':
                if (name === 'DefaultScene') Factory = DefaultScene;
                break;

        }
        let element = Factory.create(this.viewer);
        this.elements[name] = {
            el: element,
            factory: Factory
        };
        return element;
    }
}