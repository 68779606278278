
import {BaseClass, IBaseClass} from "./BaseClass"

export type EventHandler = (eventName:EventIdent, eventContext:EventContext) => void;
export type EventContext = {};
export type EventIdent = string;


export interface ActiveEventListeners {
    [key:string] : Array<EventListener>
}

export interface IEvents extends IBaseClass {

    on(evtName:EventIdent, callback:EventHandler, one:boolean) :IEventListener

    one(evtName:EventIdent, callback:EventHandler) :IEventListener

    off(evtListener:IEventListener) :void

    trigger(evtName:EventIdent, eventContext:EventContext) :void

    getActiveEventListeners() :Object
}

export class Events extends BaseClass implements IEvents {

    activeEventListeners:ActiveEventListeners = {};

    constructor() {
        super();
    }

    on(evtName:EventIdent, callback: EventHandler, one: boolean = false) :IEventListener {
        let eventListener = new EventListener(evtName, callback, one, this);
        let ae = this.activeEventListeners;
        if (!ae.hasOwnProperty(evtName)) {
            ae[evtName] = [];
        }
        ae[''+evtName+''].push(eventListener);
        return eventListener;
    }

    one(evtName:EventIdent, callback:EventHandler) :IEventListener {
        return this.on(evtName, callback, true);
    }

    off(evtListener:EventListener) :void {
        if (this.activeEventListeners.hasOwnProperty(''+evtListener._evtName+'')) {
            let ae = this.activeEventListeners[''+evtListener._evtName+''];
            for (let i in ae) {
                if (ae.hasOwnProperty(i)) {
                    delete ae[i];
                    break;
                } else {
                    console.log('no evtListener present');
                }
            }
        }
    }

    trigger(
        evtName:EventIdent,
        eventContext:EventContext
    ) :void {
//        console.log('trigger: '+ evtName);
//        console.debug(eventContext);

        let inst=this;
        if (this.activeEventListeners.hasOwnProperty(''+evtName+'')) {
//            console.log('found listeners');
            let ae = this.activeEventListeners[''+evtName+''];
            for (let evtListener of ae) {
                if (evtListener) {
//                console.log('listener selected')
//                console.debug(evtListener);
                    evtListener._callback.call(inst, evtName, eventContext);
//                console.log('listener called');
                    if (evtListener._one) inst.off(evtListener);
                }

            }
        }
    }

    getActiveEventListeners() {
        return this.activeEventListeners;
    }

}

export interface IEventListener {

    off() : boolean

    on(eventName? : EventIdent, one? : boolean) : boolean

    one(eventName? : EventIdent) : boolean

    getEventName() : EventIdent

    getEventHandler() : EventHandler

    isOne() : boolean

    isListening() : boolean

}

export class EventListener {

    /**
     *
     */
    _evtName:EventIdent;

    /**
     *
     */
    _callback:EventHandler;

    /**
     * one-time event handle
     */
    _one:boolean;

    /**
     * base ref to parent object
     */
    _base:IEvents;

    constructor(evtName:EventIdent, callback:EventHandler, one:boolean = false, base:IEvents) {
        this._evtName = evtName;
        this._callback = callback;
        this._one = one;
        this._base = base;
    }

    off() : boolean {
        this._base.off(this);
        return true;
    }

    on(eventName?:EventIdent, one?:boolean): boolean {
        if (this.off()) {
            if (eventName) this._evtName = eventName;
            if (one !== undefined) this._one = one;
            let activeEventListeners = this.getEventBase().getActiveEventListeners();
            if (!activeEventListeners.hasOwnProperty(this._evtName)) {
                activeEventListeners[''+this._evtName+''] = [];
            }
            activeEventListeners[''+this._evtName+''].push(this);
        }
        return true;
    }

    one(eventName?:EventIdent): boolean {
        if (this.off()) {
            if (eventName) this._evtName = eventName;
            return this.on(this._evtName, true);
        }
        return false;
    }

    getEventBase() : any {
        return this._base;
    }

    getEventName() : EventIdent {
        return this._evtName;
    }

    getEventHandler() : EventHandler {
        return this._callback;
    }

    isOne() : boolean {
        return this._one;
    }

    isListening() : boolean {
        let activeEventListeners = this.getEventBase().getActiveEventListeners();
        let r=false;
        if (activeEventListeners.hasOwnProperty(''+this.getEventName()+'')) {
            let ae = activeEventListeners[''+this.getEventName()+''];
            for (let evtListener of ae) {
                if (evtListener === this) {
                    r=true;
                    break;
                }
            }
        }
        return r;
    }

}