import {HemisphericLight} from "babylonjs";
import {Vector3} from "babylonjs";
import {Color3} from "babylonjs";

export function create(Viewer) {

    // Scene
    let Scene = Viewer.SceneManager.Scene;

    // Lights
    let light = new HemisphericLight("light1", new Vector3(0, 1, 0), Scene);
        light.intensity = 0.6;
        light.specular = Color3.Black();

    return light;

}