import {ArcRotateCamera} from "babylonjs";
import {Vector3} from "babylonjs";
import {Animation, CubicEase, Curve3, EasingFunction} from "babylonjs";
import {IViewer} from "../Viewer";


class DefaultCamera extends ArcRotateCamera {

        presenterAnimation:boolean = false;

        spinTo(whichprop, targetval, speed) {
                var ease = new CubicEase();
                ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
                //ease.setEasingMode(EasingFunction.EASINGMODE_EASEIN);
                Animation.CreateAndStartAnimation('at4', this, whichprop, speed, 120, this[whichprop], targetval, 0, ease);
        }

        togglePresenterAnimation() {

                this.presenterAnimation = (!this.presenterAnimation);
                console.log('toggled presenter animation!');
        }

        osdAnimate(repeat:boolean) {
                let camera = this;
                let a = this.presenterAnimation;

                if (a) {
                        // camera spinTo control by timers
                        setTimeout(()=>{if(a) {camera.spinTo("beta", 1.2, 50)}}, 1000);
                        setTimeout(()=>{if(a) {camera.spinTo("radius", 30, 10)}}, 1000);
                        //setTimeout(()=>camera.spinTo("alpha", Math.PI/4, 50), 1000*7);
                        //setTimeout(()=>camera.spinTo("alpha", Math.PI/8, 50), 1000*8);
                        //setTimeout(()=>camera.spinTo("alpha", Math.PI/16, 50), 1000*9);

                        setTimeout(()=>{if(a) {camera.spinTo("alpha", Math.PI/4, 50)}}, 3000*3);
                        setTimeout(()=>{if(a) {camera.spinTo("alpha", Math.PI, 125)}}, 8000*3);
                        setTimeout(()=>{if(a) {camera.spinTo("alpha", 2.14, 125)}}, 9000*3);
                        setTimeout(()=>{if(a) {camera.spinTo("alpha", 1.14, 125)}}, 10000*3);
                        setTimeout(()=>{if(a) {camera.spinTo("alpha", 0, 125)}}, 11000*3);

                        setTimeout(()=>{if(a) {camera.spinTo("radius", 10, 100)}}, 18000*3);

                } else {
                        console.log('presenter is disabled');
                }

                if (repeat) setTimeout(()=>camera.osdAnimate(repeat), 10000*3);

        }
}

export function create(Viewer: IViewer) {


        let camera = new DefaultCamera(
            "camera1",
            Math.PI / 2,
            Math.PI / 1.3,
            10,
            new Vector3(0, 20, 20),
            Viewer.SceneManager.scene
        );

        /**
         *  Check Collisions
         */
        camera.checkCollisions = true;
        camera.collisionRadius = new Vector3(0.05, 0.05, 0.05);

        // maxZoomIn
        camera.lowerRadiusLimit = 0.001;
        // maxZoomOut
        camera.upperRadiusLimit = 100;
        // zoomSpeed
        camera.wheelDeltaPercentage = 0.01;
        camera.fov = 1.2
        camera.inertia = 0.9;

        camera.osdAnimate(true);


        if (false) {
        let animation = new Animation(
            "cameraSwoop", "target", 30, Animation.ANIMATIONTYPE_VECTOR3
        )
        let keyFrames = []
        keyFrames.push({
                frame: 0,
                value: camera.position.clone()
        });

        let step = 50000;

        let path = (
            Curve3.CreateCatmullRomSpline([
                camera.position.clone(),
                camera.position.clone()
                    .add(new Vector3(0, 0, 0)),
                camera.position.clone()
                        .add(new Vector3(0, 0, 0))
            ], step, false)
        ).getPoints();

        for(var i=1; i<=path.length; i++){
                var ap = path[i-1]
                keyFrames.push({
                        frame: i,
                        value: ap
                })
        }
        animation.setKeys(keyFrames);
        camera.animations = [animation];
        //animation = Viewer.SceneManager.scene.beginAnimation(camera, 0, path.length, false, 1);
        }

        return camera;

}