import {IManager} from "./IManager";
import {BaseManager} from "./BaseManager";

export class GroundManager extends BaseManager implements IManager{

    ground;

    groundsRegister={};

    defaultGroundName="DefaultGround";

    async init() {
        this.ground = await this.importByName(this.defaultGroundName,'Grounds');
    }

    hasGround(groundUnique) {
        return (this.groundsRegister.hasOwnProperty(groundUnique));
    }

    addGround(groundUnique, ground) {
        this.groundsRegister[groundUnique] = ground
    }

    delGround(groundUnique) {
        if (this.hasGround(groundUnique)) {
            delete this.groundsRegister[groundUnique];
        }
    }

    clearGroundsRegister() {
        delete this.groundsRegister;
        this.groundsRegister={};
    }


}