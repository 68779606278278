

import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";


import {SceneLoader, Vector3, MeshBuilder} from "babylonjs";
import "babylonjs-loaders";

export class CharacterManager extends BaseManager implements IManager {
    
    characterSettings = {
        'speedForward': 0.03,
        'speedBackward': 0.01,
        'speedRotation': 0.1,
        'animating': true
    };

    character;

    //defaultCharacterName:string = "Miguel";
    defaultCharacterName:string = "Foxilia_Samba";

    
    async init() {
        let character = await this.importByName(this.defaultCharacterName, 'Characters');
    }
}