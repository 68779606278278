import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

export class LightManager extends BaseManager implements IManager {

    lightsRegister={};


    async init() {
        this.addLight('HemisphericLight1', this.importLightByName('HemisphericLight1'));
        this.addLight('DirectionalLight1', this.importLightByName('DirectionalLight1'));
    }

    clearLightRegister() {
        delete this.lightsRegister;
        this.lightsRegister = {};
    }

    hasLight(lightUnique) {
        return (this.lightsRegister.hasOwnProperty(lightUnique));
    }

    addLight(lightUnique, light) {
        if (!this.lightsRegister.hasOwnProperty(lightUnique)) {
            this.lightsRegister[lightUnique] = light;
        }
    }

    delLight(lightUnique) {
        if (this.hasLight(lightUnique)) {
            delete this.lightsRegister[lightUnique];
        }
    }

    async importLightByName(name) {
        return await this.importByName(name, 'Lights');
    }

}