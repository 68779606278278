//import {Application} from "./Application/Application";
import {Viewer} from "./Application/Viewer/Viewer";
import * as BABYLON from "babylonjs"
declare global {
    interface Window { Application: any, LVPX: any, OSB: any, createViewer: any, OSBFactory: any, OSBViewer }
}

//let App = new Application();
//let v = new Viewer('OSBViewer');

//window.Application = window.Application || App;
if (!window.LVPX) {
    window.LVPX = window.LVPX ||  {
        "Cache": { "Instances":{}}
    };
} else {
    if (!window.LVPX.Cache) {
        window.LVPX.Cache = {"Instances":{}};
    }
}
window.OSBFactory = Viewer;
window.OSB = {};
window.OSB.BABYLON = BABYLON;

if (false) {
    window.OSBViewer = new window.OSBFactory('OSBViewer', {
        fitContainer: true
    });
}




