import {MeshBuilder, PointerDragBehavior, StandardMaterial, Texture, Vector3} from "babylonjs";

export function create(viewer) {

    console.log('create SB');
    let scene = viewer.SceneManager.scene;

    const box = MeshBuilder.CreateBox("box", {
        size: 2,
        width: 3,
        height: 2
    }, scene);

    // Create pointerDragBehavior in the desired mode
    //var pointerDragBehavior = new PointerDragBehavior({});
    var pointerDragBehavior = new PointerDragBehavior({dragPlaneNormal: new Vector3(0,1,0)});
    //var pointerDragBehavior = new PointerDragBehavior({dragAxis: new Vector3(0,1,0)});

    // Use drag plane in world space
    pointerDragBehavior.useObjectOrientationForDragging = true;

    // Listen to drag events
    pointerDragBehavior.onDragStartObservable.add((event)=>{
        console.log("dragStart");
        console.log(event);
    })
    pointerDragBehavior.onDragObservable.add((event)=>{
        console.log("drag");
        console.log(event);
    })
    pointerDragBehavior.onDragEndObservable.add((event)=>{
        console.log("dragEnd");
        console.log(event);
    })

    // If handling drag events manually is desired, set move attached to false
     //pointerDragBehavior.moveAttached = false;

    box.addBehavior(pointerDragBehavior);

    // define material with a standard texture by url
    var mat = new StandardMaterial("mat", scene);
    mat.backFaceCulling = false;
    let url = new URL('tex/autopartner24-logo-small.png', import.meta.url).href;
    //mat.reflectionTexture = new Texture(url, scene, true, true);
    //mat.reflectionTexture.hasAlpha = true
    //mat.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    mat.diffuseTexture = new Texture(url, scene, true, true);
    mat.diffuseTexture.hasAlpha = true;
    //mat.diffuseTexture.wAng = Math.PI;
    //mat.diffuseTexture.wrapU = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
    //mat.diffuseTexture.wrapV = BABYLON.Constants.TEXTURE_CLAMP_ADDRESSMODE;
    box.material = mat;

    return box;

}