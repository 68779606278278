import {Color3, SceneLoader, StandardMaterial, MeshBuilder} from "babylonjs";
import {Vector3} from "babylonjs";
import "babylonjs-loaders";

export function create(Viewer) {


    let CharacterManager = Viewer.CharacterManager;
    let SceneManager = Viewer.SceneManager;
    let Scene = SceneManager.scene;
    let CameraManager = Viewer.CameraManager;
    let Camera = CameraManager.camera;

    let InputMap = Viewer.ActionManager.InputMap;


    //let url = new URL('HVGirl.glb', import.meta.url).href;
    let url = new URL('untitled4.glb', import.meta.url).href;
    console.log(url);
    // Load hero character
    SceneLoader.ImportMesh("", "", url, Scene, function (newMeshes, particleSystems, skeletons, animationGroups) {




        let Character = newMeshes[0];

        Character.position = new Vector3(0,0,0.1);

        console.log('AnimationGroups');
        console.debug(animationGroups);

        console.log('Skeletons');
        console.debug(skeletons);

        console.log('meshes');
        console.debug(newMeshes);


        //let skinColor = new Color3(239/255, 214/255, 189/255);
        let skinColor = new Color3(222/255, 171/255, 127/255);


        let xMaterial2 = new StandardMaterial("xxxxxxx", Scene);
        xMaterial2.diffuseColor = Color3.Black();
        xMaterial2.specularColor = new Color3(0.2, 0.2, 0.2);
        let xMesh2 = newMeshes[7];
        xMesh2.material = xMaterial2;

        let xMaterial6 = new StandardMaterial("xxxxxxx", Scene);
        xMaterial6.diffuseColor = Color3.Black();
        xMaterial6.specularColor = new Color3(0.2, 0.2, 0.2);
        let xMesh6 = newMeshes[6];
        xMesh6.material = xMaterial2;

        // Oberteil
        let shoesMesh = newMeshes[8];
        let shoesMaterial = new StandardMaterial("shoesMaterial", Scene)
            shoesMaterial.diffuseColor = Color3.Black();
            shoesMaterial.specularColor = new Color3(0.2, 0.2, 0.2);
        shoesMesh.material = shoesMaterial; //180,125,73

        // Pants
        let pantsMesh = newMeshes[9];   // pants
        let pantsMaterial = new StandardMaterial("pantsMaterial", Scene);
            pantsMaterial.diffuseColor = Color3.White();
            pantsMaterial.specularColor = new Color3(0.2, 0.2, 0.2);
        pantsMesh.material = pantsMaterial;

        // Gesicht
        let shirtMesh = newMeshes[5]; // shirt
        let shirtMaterial = new StandardMaterial("shirtMaterial", Scene);
            shirtMaterial.diffuseColor = skinColor;
            shirtMaterial.specularColor = new Color3(0.2, 0.2, 0.2);
        shirtMesh.material = shirtMaterial;
        //shirtMesh.setEnabled(false);

        // Hände
        let eyesOutMesh = newMeshes[3]; // augen außen
        let eyeOuterMaterial = new StandardMaterial('eyeMaterial', Scene);
            eyeOuterMaterial.diffuseColor = skinColor;
            eyeOuterMaterial.specularColor = new Color3(0.2, 0.2, 0.2);
        eyesOutMesh.material = eyeOuterMaterial;

        // Linkes Auge
        /*
        let xMesh = newMeshes[1];
        let xMaterial = new StandardMaterial("leftEyeMaterial", Scene);
        xMaterial.diffuseColor = Color3.Blue();
        xMesh.material = xMaterial;
    */

        // Rechtes Auge
        /*
        let eyesInnerMesh = newMeshes[2]; // augen innen
        let eyeInnerMaterial = new StandardMaterial('rightEyeMaterial', Scene);
            eyeInnerMaterial.diffuseColor = Color3.Blue();
        eyesInnerMesh.material = eyeInnerMaterial;
        */
        // Haare
        let HairMesh = newMeshes[4]; // haare
        let hairMaterial = new StandardMaterial("HairMaterial", Scene);
            hairMaterial.diffuseColor = Color3.Black();
        HairMesh.material = hairMaterial;


        console.log('Character Meshes');
        console.debug(newMeshes);
        console.debug(Character);

        CharacterManager.character = Character;
        //Scale the model down
        Character.scaling.scaleInPlace(5);

        //Lock camera on the character

        Viewer.CameraManager.camera.setTarget(new Vector3(0,7.5,0));
        console.log('cam');
        console.debug(Viewer.CameraManager.camera.getTarget());

        const walkAnim = Scene.getAnimationGroupByName("Walking");
        const walkBackAnim = Scene.getAnimationGroupByName("WalkingBack");
        //const idleAnim = Scene.getAnimationGroupByName("Idle");
        const idleAnim = Scene.getAnimationGroupByName("Armature.001|mixamo.com|Layer0.001");
        const sambaAnim = Scene.getAnimationGroupByName("Samba");


        let AvaCfg = CharacterManager.characterSettings;
        let speedForward = AvaCfg.speedForward;
        let speedBackward = AvaCfg.speedBackward;
        let speedRotation = AvaCfg.speedRotation;
        let animating = AvaCfg.animating;

        //Rendering loop (executed for everyframe)
        Scene.onBeforeRenderObservable.add(function() {

            let keydown = false;
            //Manage the movements of the character (e.g. position, direction)
            if (InputMap["w"]) {
                Character.moveWithCollisions(Character.forward.scaleInPlace(parseFloat(speedForward)));
                keydown = true;
            }
            if (InputMap["s"]) {
                Character.moveWithCollisions(Character.forward.scaleInPlace(-parseFloat(speedBackward)));
                keydown = true;
            }
            if (InputMap["a"]) {
                Character.rotate(Vector3.Up(), -parseFloat(speedRotation));
                keydown = true;
            }
            if (InputMap["d"]) {
                Character.rotate(Vector3.Up(), parseFloat(speedRotation));
                keydown = true;
            }
            if (InputMap["b"]) {
                keydown = true;
            }


            //Manage animations to be played
            if (keydown) {

                // set camera focus to character
                if (false) {
                    Viewer.CameraManager.camera.setTarget(Character);
                    let cameraDistance = Vector3.Distance(Viewer.CameraManager.camera.position, Character.position);
                    console.log('camera to target distance:'+ cameraDistance);
                    let inpEl:HTMLInputElement = window.document.querySelector('.camDistToTarget');
                    inpEl.value=cameraDistance.toString();
                }


                if (!animating) {
                    animating = true;
                    if (InputMap["s"]) {
                        //Walk backwards
                        walkBackAnim.start(true, 1.0, walkBackAnim.from, walkBackAnim.to, false);
                    } else if (InputMap["b"]) {
                        //Samba!
                        sambaAnim.start(true, 1.0, sambaAnim.from, sambaAnim.to, false);
                    } else {
                        //Walk
                        walkAnim.start(true, 1.0, walkAnim.from, walkAnim.to, false);
                    }
                }
            } else {

                if (animating) {
                    //Default animation is idle when no key is down
                    idleAnim.start(true, 1.0, idleAnim.from, idleAnim.to, false);

                    if (true) {
                        setTimeout(()=>{
                            idleAnim.stop();
                            //sambaAnim.start(true, 1.0, sambaAnim.from, sambaAnim.to, false);
                        }, 1000);
                    }

                    if (false) {
                        setTimeout(()=>{
                            idleAnim.start(true, 1.0, idleAnim.from, idleAnim.to, false);
                            //sambaAnim.stop();
                        }, 30000);
                    }


                    //Stop all animations besides Idle Anim when no key is down
                    //sambaAnim.stop();
                    //walkAnim.stop();
                    //walkBackAnim.stop();

                    //Ensure animation are played only once per rendering loop
                    animating = false;
                }
            }
        });
    });

}