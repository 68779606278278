import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";


export class MeshManager extends BaseManager implements IManager {

    meshes:Array<any> = [];

    async init() {

    }
}