import {
    Animation,
    Color4,
    CubicEase,
    EasingFunction,
    FilesInput,
    MeshBuilder,
    Scene,
    SceneLoader,
    Tools,
    Vector3
} from "babylonjs";
import {StandardMaterial} from "babylonjs";
import {CubeTexture} from "babylonjs";
import {Texture} from "babylonjs";
import {Color3} from "babylonjs";



// required imports
import "babylonjs-loaders";

export async function create(Viewer: { CameraManager: { init: () => Promise<any>; camera: { setTarget: (arg0: any) => void; }; }; SceneManager: { addCameraToScene: (arg0: any) => void; }; ActionManager: { init: () => Promise<any>; }; CharacterManager: { init: () => Promise<any>; character: undefined; }; GroundManager: { init: () => Promise<any>; }; LightManager: { init: () => Promise<any>; }; MeshManager: { init: () => Promise<any>; }; }) {

    Viewer.CameraManager.init().then(() => {

        console.log('CameraManager init done!');

        Viewer.SceneManager.addCameraToScene(Viewer.CameraManager.camera);

        Viewer.ActionManager.init().then(() => {
            Viewer.CharacterManager.init().then(() => {
                if (Viewer.CharacterManager.character !== undefined) {
                    Viewer.CameraManager.camera.setTarget(Viewer.CharacterManager.character);
                }
                Viewer.GroundManager.init().then(() => {
                    Viewer.LightManager.init().then(() => {
                        Viewer.MeshManager.init().then(() => {
                            addObjectsToScene(Viewer);



                            // The CSS object will follow this mesh
                            //var plane = MeshBuilder.CreatePlane("plane", {width: 1, height: 1}, scene);
                            //plane.scaling.x = 6
                            //plane.scaling.y = 4

                            // Setup the CSS renderer and Youtube object
                            //let renderer = setupRenderer();
                            //createCSSobject(plane, scene, 'qgKbpe4qvno', renderer);
                            //createMaskingScreen(plane, scene, renderer)




                        });
                    });
                });
            });
        });
    });

    // GUI
    /*
    var advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
    var instructions = new BABYLON.GUI.TextBlock();
    instructions.text = "Move w/ WASD keys, B for Samba, look with the mouse";
    instructions.color = "white";
    instructions.fontSize = 16;
    instructions.textHorizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
    instructions.textVerticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_BOTTOM
    advancedTexture.addControl(instructions);
    */
    // Ground

}

function attachHtml(id , W, H ,L , T ,p ,ts)
{
    let deg = 180 / Math.PI;
    let el;
    let exist = false;
    if(document.getElementById('spDiv'+id)) {
        exist = true;
        el = document.getElementById('spDiv'+id);
    }

    let zone = document.getElementById('viewer');
    let w = zone.offsetWidth.valueOf()*1;
    let h = zone.offsetHeight.valueOf()*1;


    el.setAttribute('style', 'transform: scale(1.0) scaleZ(1.0) rotateX('+ts.rx+'deg) rotateY('+ts.ry+'deg) rotateZ('+ts.rz+'deg);transform-origin: 50% 50%;perspective: '+p+';-webkit-transform: scale(1.0) scaleZ(1.0) rotateX('+ts.rx+'deg) rotateY('+ts.ry+'deg)  rotateZ('+ts.rz+'deg);-webkit-transform-origin: 50% 50%;-moz-transform: scale(1.0) scaleZ(1.0) rotateX('+ts.rx+'deg)  rotateY('+ts.ry+'deg) rotateZ('+ts.rz+'deg);-moz-transform-origin: 50% 50%;-o-transform: scale(1.0) scaleZ(1.0) rotateX('+ts.rx+'deg)  rotateY('+ts.ry+'deg) rotateZ('+ts.rz+'deg);-o-transform-origin: 50% 50%;-ms-transform: scale(1.0) scaleZ(1.0) rotateX('+ts.rx+'deg)  rotateY('+ts.ry+'deg) rotateZ('+ts.rz+'deg);-ms-transform-origin:50% 50%;transform: scale(1.0) scaleZ(1.0) rotateX('+ts.rx+'deg)  rotateY('+ts.ry+'deg)  rotateZ('+ts.rz+'deg);transform-origin: 50% 50%;position:absolute;top:'+(h/2-H/2+T)+'px;left:'+(w/2-W/2+L)+'px;z-index:100;background-color:#ffffff;width:'+W+'px;height:'+H+'px;opacity:1.;');
    el.id = "spDiv"+id;
    el.className = "acp-important";
    zone.setAttribute('style','-o-perspective: '+p+';-o-perspective-origin: 50% 50%;-webkit-perspective: '+p+';-webkit-perspective-origin: 50% 50%;-moz-perspective: '+p+';-moz-perspective-origin: 50% 50%;-ms-perspective: '+p+';-ms-perspective-origin: 50% 50%;perspective: '+p+';perspective-origin: 50% 50%;position:relative');

    if(!exist) {
        el.innerHTML = '<iframe width="1280" height="720" src="https://www.youtube.com/embed/YKqXcrWliww" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        zone.appendChild(el);
    }
}

function createPage(scene: { registerBeforeRender: (arg0: () => void) => void; }, camera: { position: any; fov: number; }, url = "6y4BMVhkQPs")
{
    scene.registerBeforeRender(function() {
        let cp = camera.position;
        let dx = Math.atan(Math.sqrt(cp.x * cp.x + cp.z * cp.z) / cp.y) * 180 / Math.PI;
        let dy = 0;
        let sign = 1;
        let baseSize = 180;
        if(dx > 0) { sign = -1; }
        let dz =  sign*Math.atan(cp.x/cp.z) * 180./Math.PI;
        let size = baseSize * camera.fov / Math.sqrt(Math.pow(cp.x, 2.0) + Math.pow(cp.y, 2.0) + Math.pow(cp.z, 2.0));
        attachHtml('PageWeb', baseSize * size, baseSize * size, 0, 0, '800px', {rx: dx, ry: dy, rz: dz} );
    });
}







function addObjectsToScene(Viewer) {
    // Low Poly Character with Blender Tutorial of Grant Abbitt: https://www.youtube.com/user/mediagabbitt
    // Character animations by Mixamo: https://www.mixamo.com/

    Viewer.engine.enableOfflineSupport = false;

    Viewer.SceneManager.scene.clearColor = new Color4(255,255,255,0);

    let scene = Viewer.SceneManager.scene;
    let engine = Viewer.engine;
    let sceneLoaded = Viewer.SceneManager.scene;
    let canvas = Viewer.canvas;

   // let videoTexture = Viewer.MeshManager.importByName('VideoTexture', 'Meshes');
    /*
    let panel = CreateMediaPlayerPanel(Viewer);
        panel.position = new Vector3(0, -10, 3);
    */


  //  createPage(Viewer.SceneManager.scene, Viewer.CameraManager.camera);


    let filesInput = new FilesInput(
        engine,
        null,
        sceneLoaded,
        null,
        null,
        null,
        function () {
            Tools.ClearLogCache()
        },
        null,
        function(){

        });

    filesInput.monitorElementForDragNDrop(canvas);


    //let skyBoxClouds = Viewer.MeshManager.importByName('SkyboxClouds', 'Meshes');
    let skyBoxTropical = Viewer.MeshManager.importByName('SkyboxTropical', 'Meshes');

    //let skyBoxCity = Viewer.MeshManager.importByName('SkyboxCity', 'Meshes');
    let simpleBox = Viewer.MeshManager.importByName('SimpleBox', 'Meshes');

}