
import {Color3, CubeTexture, MeshBuilder, StandardMaterial, Texture} from "babylonjs";
import {Vector3} from "babylonjs";

export function create(Viewer) {

    let scene = Viewer.SceneManager.scene;

    let skybox = MeshBuilder.CreateBox("skyBox", {size:1024.0}, scene);
    let skyboxMaterial = new StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;

    skyboxMaterial.reflectionTexture = new CubeTexture("", scene, null, null, [
        new URL('tex/tropical/TropicalSunnyDay_px.jpg', import.meta.url).href,
        new URL('tex/tropical/TropicalSunnyDay_py.jpg', import.meta.url).href,
        new URL('tex/tropical/TropicalSunnyDay_pz.jpg', import.meta.url).href,
        new URL('tex/tropical/TropicalSunnyDay_nx.jpg', import.meta.url).href,
        new URL('tex/tropical/TropicalSunnyDay_ny.jpg', import.meta.url).href,
        new URL('tex/tropical/TropicalSunnyDay_nz.jpg', import.meta.url).href,
    ]);

     /*
    skyboxMaterial.reflectionTexture = new CubeTexture("", scene, null, null, [
        new URL('tex/paper.png', import.meta.url).href,
        new URL('tex/paper.png', import.meta.url).href,
        new URL('tex/paper.png', import.meta.url).href,
        new URL('tex/paper.png', import.meta.url).href,
        new URL('tex/paper.png', import.meta.url).href,
        new URL('tex/paper.png', import.meta.url).href,
    ]);
*/
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    skyboxMaterial.specularColor = new Color3(0, 0, 0);
    skyboxMaterial.alpha = 0.99;
    skybox.material = skyboxMaterial;
    skybox.position = new Vector3(0,0,0);

    return skybox;

}