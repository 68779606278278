import {Vector3} from "babylonjs";
import {ArcRotateCamera} from "babylonjs";

import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

export class CameraManager extends BaseManager implements IManager {

    camera;

    defaultCameraName:string = "DefaultCamera";

    async init() {
        let s = this;
            s.camera = await this.createCamera();
    }

    async createCamera() {
        let name = this.defaultCameraName;
        return await this.importByName(name, 'Cameras');
    }



}